import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appDecimalNumber]'
})
export class DecimalNumberDirective {

  // teoricamente l'input serve per il numero di decimali, ma non risco a mettere la variabile in regex
  @Input() num_decimal: number = 3;
  // private regex_point: RegExp = new RegExp(/^\d*\.?\d{0,3}$/g);
  // private regex_dot: RegExp = new RegExp(/^\d*\,?\d{0,3}$/g);
  private regex_dot!: RegExp;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {
  }

  /**
   * Se rientra nel path allora lo accetta
   * @param event
   * @returns
   */
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {

    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;

    const position = this.el.nativeElement.selectionStart;

    let next: string =
      [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)]
        .join('');

    next = next.replace('.', ',');
    this.regex_dot = new RegExp(`^\\d*\\,?\\d{0,${this.num_decimal}}$`, 'g');
    if (next && !String(next).match(this.regex_dot)) {
      event.preventDefault();
    }

  }

  /**
   * Quando clicco automaticamente cambia il punto con la virgola
   * @param event
   */
  @HostListener('input', ['$event'])
  onInput(event: KeyboardEvent) {
    this.el.nativeElement.value = this.el.nativeElement.value.replace('.', ',');


  }

}

