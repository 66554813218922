import { NgModule } from '@angular/core';
import { DecimalNumberDirective } from '../../directives/decimal-number.directive';



@NgModule({
  declarations: [DecimalNumberDirective],
 exports: [DecimalNumberDirective]
})
export class DirectivesModule { }
